// Fonts
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.eot');
  src: url('../assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('../assets/fonts/Roboto-Regular.woff') format('woff'),
      url('../assets/fonts/Roboto-Regular.ttf') format('truetype'),
      url('../assets/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Medium.eot');
  src: url('../assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Roboto-Medium.woff2') format('woff2'),
      url('../assets/fonts/Roboto-Medium.woff') format('woff'),
      url('../assets/fonts/Roboto-Medium.ttf') format('truetype'),
      url('../assets/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
html, body {
  font-family: 'Roboto';
  font-weight: normal;
  font-style: normal;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.8);
}
@media (min-width: 576px) {
  .nav-user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-top: -8px;
  }
}
@desktop:   ~"only screen and (max-width: 1199px)";
@tablet:    ~"only screen and (max-width: 992px)";
@mobile:    ~"only screen and (max-width: 768px)";

.nav-user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 2rem;
  margin-top: -4px;
  width: 2rem;
}

.form-group.required .col-form-label:after {
  color: #d00;
  content: "*";
  position: absolute;
  margin-left: 3px;
}

/* Sweet alert */
.swal2-title {
  display: inline-block;
}

body.swal2-height-auto {
  height: inherit !important;
}

.swal2-popup.swal2-toast {
  padding: 10px 12px !important;
  display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
  text-align: left !important;
  margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
  margin-bottom: 4px !important;
}
.swal2-container {
  .swal2-popup.swal2-toast {
    &.swal2-show {
      display: flex !important;
    }
  }
  .swal2-popup {
    border-radius: 7px;
    background-color: #f5f5f5;
    background-image: url("assets/img/BgPattern.png");
    background-repeat: repeat;
    background-size: auto;
    .swal2-icon {
      border-radius: 7px;
      width: 50px;
      height: 50px;
      border: 2px solid #36B269;
      .swal2-icon-content {
        width: 100%;
        height: 100%;
        justify-content: center;
        .icon-wrap {
          font-size: 24px;
        }
      }
    }
    .swal2-actions {
      button {
        margin: 5px;
        border-radius: 7px;
        &.swal2-confirm {
          background-color: #36B269;
          &:hover {
            background-color: #36B269;
          }
        }
        &.swal2-cancel {
          background-color: #fff;
          color: rgba(0,0,0,0.8);
          &:hover {
            background-color: #fff;
            color: rgba(0,0,0,0.8);
          }
        }
        &:focus, &:active {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

div.wrapper,
div.content-wrapper,
div.sidebar {
  min-height: 100vh;
}

table.table {
  * {
    font-size: 14px !important;
  }

  th,
  td {
    padding: 8px;
    vertical-align: middle;
  }
}

table-card-footer * {
  font-size: 14px;
}
.wrapper {
  background: #eeeeee;
  overflow-x: hidden;
}
.wrapper-container {
  width: 100%;
  max-width: 960px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #f5f5f5;
  background-image: url("assets/img/BgPattern.png");
  background-repeat: repeat;
  background-size: auto;
  overflow: hidden;
}
.top-bar {
  width: 100%;
  height: 90px;
  padding: 0 60px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 0;
  @media @mobile {
    padding: 0 30px;
  }
  h5 {
    font-size: 22px;
    font-weight: normal;
    @media @mobile {
      font-size: 20px;
    }
  }
}
.top-bar-logo {
  width: 30px;
  height: auto;
  margin-right: 10px;
  @media @mobile {
    width: 20px;
  }
}
.profile-name {
  font-size: 17px;
  margin-right: 10px;
  @media @mobile {
    display: none;
  }
}
.profile-image {
  width: 40px;
  height: 40px;
  background: #f3f3f3;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #b9b9b9;
  cursor: pointer;
}
.top-green-bar {
  width: 100%;
  height: 200px;
  padding: 45px 60px 0 60px;
  background-image: linear-gradient(to right, rgba(33,170,118,1) , rgba(112,199,72,1));
  position: relative;
  z-index: 0;
  overflow: hidden;
  &:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("assets/img/BgPattern.png");
    background-repeat: repeat;
    background-size: auto;
  }
  @media @mobile {
    padding: 45px 30px 0 30px;
  }
  .top-bar-logo {
    width: 26px;
  }
  h6 {
    font-size: 22px;
    font-weight: normal;
    color: #fff;
    @media @mobile {
      font-size: 20px;
    }
  }
  h5 {
    color: #fff;
    font-size: 30px;
    font-weight: normal;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 300px;
    height: 300px;
    position: absolute;
    right: -100px;
    top: -100px;
    z-index: -1;
    border: 50px solid #BCEAA3;
    border-radius: 50%;
    opacity: 0.3;
  }
}
.font-weight-normal {
  font-weight: normal;
}
.font-weight-medium {
  font-weight: 500;
}
.font-size-sm {
  font-size: 1rem;
}
.font-size-md {
  font-size: 20px;
}
.font-size-lg {
  font-size: 25px;
}
p {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 25px;
}
.letter-space-2 {
  letter-spacing: 2px;
}
.content-area {
  padding: 30px 60px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  &::after {
    content: "";
    display: inline-block;
    width: 300px;
    height: 300px;
    position: absolute;
    right: -120px;
    top: -200px;
    z-index: -1;
    border: 50px solid #E2E2E2;
    border-radius: 50%;
    opacity: 0.3;
  }
  @media @mobile {
    padding: 30px;
  }
}
app-green-header + .content-area {
  overflow: visible;
  &::after {
    content: none;
  }
}
.form-card {
  background-color: #fff;
  border-radius: 7px;
  padding: 25px;
  .form-group {
    margin: 0;
  }
  label:not(.form-check-label):not(.custom-file-label) {
    font-size: 18px;
    padding: 0;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.7);
    @media @mobile {
      font-size: 16px;
    }
  }
  .form-control {
    border: none;
    padding: 0;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    height: auto;
    border-radius: 10px;
  }
  .form-control.inputDate.is-invalid, .was-validated .form-control.inputDate:invalid, .form-control.inputDate {
    background: none;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path opacity="1" fill="%2336B269" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/></svg>');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 20px;
    border-radius: 0;
  }
  .invalid-feedback {
    font-size: 0.7rem;
  }
  .input-group {
    .input-group-prepend, .input-group-append {
      .input-group-text {
        background-color: #EBEBEB;
        border: none;
        padding: 6px 22px;
        i {
          font-size: 17px;
        }
      }
    }
    .input-group-prepend {
      .input-group-text {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }
    }
    .input-group-append {
      .input-group-text {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
    .form-control {
      width: 75%;
      flex: none;
      padding: 15px 0px;
      background-color: #F5F5F5;
      &::-webkit-input-placeholder {
        font-size: 16px;
      }
      &::-moz-placeholder {
        font-size: 16px;
      }
      &:-ms-input-placeholder {
        font-size: 16px;
      }
      &:-moz-placeholder {
        font-size: 16px;
      }
      &.responsive-width {
        width: 35%;
        @media @mobile {
          width: 75%;
        }
      }
      &.inputNumber {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 200px;
      }
    }
  }
  select.form-select {
    width: 100%;
    background-color: #F5F5F5;
    font-size: 18px;
    padding: 18px 15px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.7);
    border: none;
    border-radius: 7px;
    &:focus, &:active {
      border: none;
      outline: none;
    }
    @media @mobile {
      font-size: 16px;
    }
  }
}
.form-card .input-group:has(.input-group-prepend) .form-control,
.form-card .input-group:has(.input-group-append) .form-control {
  padding-left: 15px;
  padding-right: 15px;
}
.info-text {
  display: flex;
  align-items: baseline;
  font-size: 16px;
  @media @mobile {
    font-size: 14px;
  }
  i {
    margin-right: 10px;
  }
  span {}
  a {
    text-decoration: underline;
    color: inherit;
  }
}
.text-color {
  color: #36B269;
}
.text-danger {
  color: #F19097 !important;
}
.text-red {
  color: #E33501 !important;
}
.custom-radio, .custom-checkbox {
  position: relative;
  width: max-content;
  cursor: pointer;
  .custom-radio-input, .custom-checkbox-input {
    position: absolute;
    opacity: 0;
    width: calc(100% - 50px);
    height: 100%;
    z-index: 1;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
  label:not(.form-check-label):not(.custom-file-label), select {
    margin: 0;
    background: #F5F5F5;
    color: #404040;
    opacity: 0.7;
    border-radius: 7px;
    border: 2px solid transparent;
    padding: 7px 15px;
    font-size: 18px;
    cursor: pointer;
    @media @mobile {
      font-size: 16px;
    }
  }
  select.form-control {
    padding: 7px 50px 7px 25px;
    color: rgba(64,64,64,1);
    font-size: 18px;
    border-radius: 7px;
    border: 2px solid transparent;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 15px;
    @media @mobile {
      font-size: 16px;
    }
  }
}
.custom-radio .custom-radio-input:checked ~ label {
  background-color: rgba(54, 178, 105, 0.05);
  border-color: rgba(54, 178, 105, 1);
  color: rgba(0, 0, 0, 0.8);
}
.custom-checkbox .custom-checkbox-input:checked ~ label {
  background-color: rgba(54, 178, 105, 0.05);
  border-color: rgba(54, 178, 105, 1);
  color: rgba(0, 0, 0, 0.8);
}
.custom-radio .custom-radio-input:checked ~ select {
  background-color: rgba(54, 178, 105, 0.05);
  border-color: rgba(54, 178, 105, 1);
  color: rgba(0, 0, 0, 0.8);
}
.custom-radio .custom-radio-input.disabled ~ label,
.custom-checkbox .custom-checkbox-input.disabled ~ label {
  cursor: not-allowed;
}
.custom-checkbox {
  label {
    display: flex;
    align-items: center;
    &:before {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      background-color: #fff;
      border: 1px solid #E3E3E3;
      border-radius: 7px;
      margin-right: 15px;
      font-family: "Font Awesome 5 Free";
      content: "\f00c";
      font-weight: 900;
      font-size: 70%;
      color: #fff;
    }
  }
}
.custom-checkbox .custom-checkbox-input:checked ~ label:before {
  border-color: #E8F5EE;
  color: #36B269;
}
.custom-btn {
  background: rgba(54, 178, 105, 1);
  color: #fff;
  font-size: 20px;
  padding: 15px 30px;
  border-radius: 7px;
  line-height: normal;
  transition: all 0.3s;
  cursor: pointer;
  &:disabled {
    background-color: #b2b2b2;
    color: #fff;
  }
  @media @mobile {
    padding: 10px 30px;
    font-size: 16px;
  }
  i {
    font-size: 20px;
    margin-left: 10px;
    transition: all 0.3s;
    @media @mobile {
      font-size: 16px;
    }
  }
  &:hover {
    color: #fff;
    padding-right: 20px;
    i {
      margin-left: 20px;
    }
  }
}
.btn-white {
  background: #fff;
  color: rgba(0,0,0,0.8);
  font-size: 20px;
  padding: 15px 30px;
  line-height: normal;
  border-radius: 7px;
  transition: all 0.3s;
  &:disabled {}
  @media @mobile {
    padding: 10px 30px;
    font-size: 16px;
  }
  i {
    font-size: 20px;
    margin-right: 10px;
    transition: all 0.3s;
    @media @mobile {
      font-size: 16px;
    }
  }
  &:hover {
    color: rgba(0,0,0,0.8);
    padding-left: 20px;
    i {
      margin-right: 20px;
    }
  }
}
.btn-transparent {
  background-color: rgba(54,178,105,0.1);
  font-size: 15px;
  padding: 4px 17px;
}
.btn-close {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  &:hover {
    color: #fff;
  }
}
.custom-btn-outline {
  border: 2px solid #36B269;
  color: #36B269;
  border-radius: 7px;
  font-size: 16px;
  padding: 8px 15px;
  &:hover {
    color: #36B269;
  }
  &:disabled {
    border-color: #b2b2b2;
    color: #808080;
  }
}
.custom-btn-xs {
  font-size: 14px;
  padding: 4px 10px;
}
.validationMessage {
  font-weight: normal !important;
  margin: 0 !important;
  font-size: 80% !important;
}
.cardTitle {
  font-size: 21px;
  letter-spacing: 2.6px;
  @media @mobile {
    font-size: 16px;
  }
}
.credit-score {
  background: #F8F8F8;
  font-weight: 500;
  font-size: 22px;
  width: max-content;
  padding: 8px 16px;
  border-radius: 7px;
  @media @mobile {
    font-size: 18px;
  }
  span {}
  &.bad {}
  &.fair {}
  &.good {
    color: #36B269;
  }
  &.excellent {}
}
.creditDesc {
  font-size: 18px;
  line-height: 26px;
  @media @mobile {
    font-size: 14px;
  }
}
.creditItem {
  .crTitle {
    @media @mobile {
      font-size: 16px;
    }
  }
  h5 {
    @media @mobile {
      font-size: 21px;
    }
  }
}
ul.page-tabs {
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 7px;
  padding: 0 30px;
  margin-top: calc(-30px + (-73px/2));
  margin-bottom: 30px;
  li {
    margin: 0;
    display: flex;
    padding: 0 40px;
    @media @mobile {
      padding: 0 20px;
    }
    a {
      padding: 20px 0;
      color: rgba(0,0,0,0.8);
      font-size: 20px;
      position: relative;
      @media @mobile {
        font-size: 16px;
      }
      &:after {
        content: "";
        display: block;
        width: 60px;
        height: 5px;
        border-radius: 7px;
        background-color: transparent;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &.active {
      a {
        color: #36B269;
        &:after {
          background-color: #36B269;
        }
      }
    }
  }
}
.fluid-tabs {
  position: relative;
  width: calc(100% + 120px);
  left: 50%;
  transform: translateX(-50%);
  padding: 0 60px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  ul.page-tabs {
    margin-top: 0;
    margin-bottom: 0;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    padding: 0;
    justify-content: space-between;
    max-width: 500px;
    li {
      padding: 0;
      a {
        color: rgba(0, 0, 0, 0.5);
        font-size: 18px;
        padding: 16px 0;
        @media @mobile {
          font-size: 16px;
        }
      }
      &.active {
        a {
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}
.page-title {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: normal;
  @media @mobile {
    font-size: 20px;
  }
}
.suggestionBox {
  background-color: rgba(54, 178, 105, 0.1);
  padding: 13px 20px;
  font-size: 17px;
  border-radius: 7px;
  margin-bottom: 50px;
  i {
    color: #36B269;
    margin-right: 10px;
  }
}
.sortableWrapper {
  .sortableItem {}
}
.goal-list {
  padding-left: 25px;
  .goal-list-item {
    margin-bottom: 40px;
    position: relative;
    .item-number {
      width: 45px;
      height: 45px;
      background-color: #fff;
      color: #36B269;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
      line-height: normal;
      border: 2px solid #36B269;
      border-radius: 7px;
      position: absolute;
      left: -25px;
      top: 50px;
      @media @mobile {
        width: 32px;
        height: 32px;
        font-size: 14px;
        left: -16px;
      }
    }
    .item-card {
      background-color: #fff;
      border-radius: 7px;
      padding: 30px 30px 30px 60px;
      cursor: pointer;
      @media @mobile {
        padding: 30px 30px 30px 40px;
      }
      h4 {
        font-size: 22px;
        margin: 0;
        font-weight: normal;
        color: rgba(0, 0, 0, 1);
        @media @mobile {
          font-size: 18px;
        }
      }
      i {
        font-size: 25px;
        opacity: 0.7;
        @media @mobile {
          font-size: 20px;
        }
      }
      p {
        font-size: 18px;
        line-height: 30px;
        color: rgba(0,0,0,0.7);
        margin-bottom: 30px;
        @media @mobile {
          font-size: 14px;
        }
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 7px;
        margin-bottom: 30px;
      }
      .custom-btn {
        width: 100%;
        i {
          opacity: 1;
          font-size: 18px;
          @media @mobile {
            font-size: 16px;
          }
        }
      }
    }
    .goal-editable {
      i {
        &.fa-indian-rupee-sign {
          font-size: 20px;
          opacity: 1;
          @media @mobile {
            font-size: 16px;
          }
        }
      }
      h3 {
        font-weight: 500;
        font-size: 25px;
        margin-bottom: 10px;
        @media @mobile {
          font-size: 20px;
        }
      }
      h5 {
        font-weight: 500;
        font-size: 17px;
        margin-bottom: 0;
      }
      p {
        color: #404040;
        opacity: 0.8;
        font-size: 16px;
        line-height: 22px;
        padding-left: 25px;
        position: relative;
        white-space: normal !important;
        height: auto !important;
        overflow: visible !important;
        &::before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          margin-right: 15px;
          position: absolute;
          left: 0;
          top: 6px;
        }
      }
      .btn {
        display: inline-block !important;
        margin-left: 25px;
      }
    }
  }
}
.goal-list .goal-list-item:not(.open) {
  .item-card {
    i {
      &.fa-angle-up {
        display: none;
      }
    }
    p {
      height: 30px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }
    img {
      display: none;
    }
    .custom-btn {
      display: none;
    }
  }
}
.goal-list .goal-list-item.open {
  .item-number {
    background-color: #36B269;
    color: #fff;
  }
  .item-card {
    i {
      &.fa-angle-down {
        display: none;
      }
    }
  }
}
.splitup-card {
  padding-left: 25px;
  > span {
    display: block;
    margin-bottom: 5px;
    position: relative;
    @media @mobile {
      font-size: 14px;
    }
    &::before {
      // content: "";
      // display: inline-block;
      // width: 10px;
      // height: 10px;
      // background-color: #E2E2E2;
      // margin-right: 10px;
      position: absolute;
      left: -25px;
      top: 0;
      font-family: "Font Awesome 5 Free";
      content: "\f05a";
      font-weight: 900;
      color: #b9b9b9;
    }
  }
  p {
    font-size: 20px;
    margin: 0;
  }
}
// .row {
//   .col-6 {
//     &:nth-child(1) {
//       .splitup-card span::before {
//         background-color: #2DE2BD;
//       }
//     }
//     &:nth-child(2) {
//       .splitup-card span::before {
//         background-color: #FF6E19;
//       }
//     }
//     &:nth-child(3) {
//       .splitup-card span::before {
//         background-color: #23C4FE;
//       }
//     }
//     &:nth-child(4) {
//       .splitup-card span::before {
//         background-color: #FBE158;
//       }
//     }
//     &:nth-child(5) {
//       .splitup-card span::before {
//         background-color: #A023FE;
//       }
//     }
//     &:nth-child(6) {
//       .splitup-card span::before {
//         background-color: #9EFB58;
//       }
//     }
//   }
// }
.edit-popup-left {
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 50%;
  top: 0;
  max-width: 960px;
  transform: translateX(-50%);
  .overlayblock {
    width: 100%;
    height: 100%;
  }
  .popup-container {
    background-color: #f5f5f5;
    background-image: url("assets/img/BgPattern.png");
    background-repeat: repeat;
    background-size: auto;
    width: 70%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 30px;
    height: 100vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #E3E3E3;
      border-radius: 7px;
    }
    .col-form-label {
      word-break: break-all;
      line-height: normal;
    }
    label:not(.form-check-label):not(.custom-file-label), select {
      font-size: 16px;
    }
    label:not(.form-check-label):not(.custom-file-label) {
      @media @mobile {
        font-size: 14px;
      }
    }
    .form-card .form-control {
      @media @mobile {
        font-size: 18px;
      }
    }
  }
}
.edit-popup-left:not(.open) {
  display: none;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(#ffffff,0.7);
  z-index: 999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 960px;
  cursor: progress;
  i {
    font-size: 36px;
    color: #36B269;
  }
  p {
    margin: 0;
    padding: 20px;
    font-size: 14px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #e3e3e3;
  }
}
.rangeBar {
  width: 100%;
  height: 4px;
  background-color: #9A9A9A;
  border-radius: 4px;
  position: absolute;
  z-index: 0;
}
.range-slider {
  border-radius: 0px;
  width: 100%;
  -webkit-appearance: none;
  background-color: transparent;
  height: 30px;
  z-index: 1;
}
// .range-slider.below::-webkit-slider-runnable-track {
//   height: 6px;
//   -webkit-appearance: none;
//   color: #00A675;
// }
.range-slider::-webkit-slider-thumb {
  width: 30px;
  -webkit-appearance: none;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  background: #36B269;
  box-shadow: -900px 0 0 900px transparent;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><g id="Group_2" data-name="Group 2" transform="translate(-2002 -1360)"><rect id="Rectangle_1" data-name="Rectangle 1" width="30" height="30" rx="4" transform="translate(2002 1360)" fill="%2336b269"/><g id="Group_1" data-name="Group 1" transform="translate(4 33)"><rect id="Rectangle_2" data-name="Rectangle 2" width="2" height="12" rx="1" transform="translate(2006 1336)" fill="rgba(255,255,255,0.8)"/><rect id="Rectangle_3" data-name="Rectangle 3" width="2" height="12" rx="1" transform="translate(2012 1336)" fill="rgba(255,255,255,0.8)"/><rect id="Rectangle_4" data-name="Rectangle 4" width="2" height="12" rx="1" transform="translate(2018 1336)" fill="rgba(255,255,255,0.8)"/></g></g></svg>');
}
img.bankLogo {
  width: 120px;
  height: 25px;
  object-fit: contain;
  object-position: 0% 0%;
  filter: grayscale(100%);
}
@media @desktop {}
@media @tablet {}
@media @mobile {}

.tooltip {
  .tooltip-arrow {
    &::before {
      content: none;
    }
  }
  .tooltip-inner {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.8);
    border: 1px solid #E3E3E3;
  }
}

.skeltonLoading {
  display: none;
}
.skeltonLoaded {}
.skeltonLoading ~ .skelton {
  color: transparent;
  position: relative;
  background: #cccccc;
}
.skeltonLoading ~ .skelton::after {
  content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100px);
    background: linear-gradient(90deg, transparent, rgba(255,255,255,0.5), transparent);
    animation: loading 1s infinite;
}
@keyframes loading {
    100% {
        transform: translateX(100%);
    }
}
.skeltonLoaded ~ .skelton {
  display: none;
}
.skeltonLoading ~ .skelton1 {
  width: 300px;
  height: 50px;
}
.skeltonLoading ~ .skelton2 {
  width: 200px;
  height: 30px;
}
.skeltonLoading ~ .skelton3 {
  width: 200px;
  height: 30px;
  margin-left: 10px;
}
.skeltonLoading ~ .skelton4 {
  width: 100%;
  display: block;
  height: 30px;
  margin-bottom: 10px;
}
.skeltonLoading ~ .skelton5 {
  width: 60%;
  display: block;
  height: 30px;
}
.skeltonLoading ~ .skelton6 {
  width: 100%;
  height: 40px;
}